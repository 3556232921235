/* Global imports */
import React, { useContext } from 'react';
import pt from 'prop-types';
import Link from '../../../link';
import Icon from '../../../icon';
import * as styles from '../../styles.module.scss';
import Text from '../../../text';
import { MicrocopyContext } from '../../../../../context/microcopy.context';
import { getCopy } from '../../../../../helpers/copy';

const CompanyDetailSocials = ({ hasContact, companyWebsite, socialLinks }) => {
  const microcopy = useContext(MicrocopyContext);

  return (
    <>
      {companyWebsite && (
      <Link link={{ externalLink: companyWebsite }}>
        <Icon name="globe" type="fal" size="lg" className={styles.link} />
      </Link>
    )}
      {socialLinks &&
        socialLinks.map((social, index) => (
          <Link link={{ externalLink: social.externalLink }} key={index}>
            <Icon
              name={social.icon}
              type="fab"
              size="lg"
              className={styles.link}
            />
          </Link>
        ))}
      {hasContact && (
        <a href="#contact" className={styles.contactLink}>
          <Text as="span" type="button">
            {getCopy('label.contact', microcopy)}
          </Text>
        </a>
      )}
    </>
  );
};

CompanyDetailSocials.propTypes = {
  hasContact: pt.bool,
  companyWebsite: pt.string,
  socialLinks: pt.array,
};
CompanyDetailSocials.defaultProps = {
  hasContact: false,
  companyWebsite: '',
  socialLinks: [],
};

export default CompanyDetailSocials;
