import React, { useContext, useEffect, useState } from 'react';
import pt from 'prop-types';
import CustomScrollable from '../../../../ui/customScrollable';
import TeaserItem from '../../../../ui/teaserItem';
import FirstItem from '../firstItem';
import { headlinePropTypes } from '../../../../ui/headline';
import Link, { linkProptypes } from '../../../../ui/link';
import * as styles from './styles.module.scss';
import { getCountryName } from '../../../../../utils/constants';

import { getAPIRoot } from '../../../../../helpers/fetch';
import { useLocale } from '../../../../../context/locale.context';
import placeholderImage from '../../../../../images/placeholder-image.png';
import { getJurorFilterPageLink } from '../../../../../helpers/getJurorLink';
import { getCopy } from '../../../../../helpers/copy';
import { MicrocopyContext } from '../../../../../context/microcopy.context';
import { combine } from '../../../../../helpers/styles';

const RandomJurors = ({
  amountOfRandomTeaser,
  firstItemHeadline,
  firstItemText,
  firstItemLink,
  setTotal,
  layout,
  beigeBackground,
}) => {
  const [jurors, setJurors] = useState([]);
  const microcopy = useContext(MicrocopyContext);
  const { locale } = useLocale();

  const fetchProjects = async () => {
    let apiRoot = await getAPIRoot();

    await fetch(
      `${apiRoot}${process.env.GATSBY_API_RANDOM_JUROR}/${amountOfRandomTeaser}`
    )
      .then((res) => res.json())
      .then((res) => {
        const data = res.items.map((item) => {
          return {
            image: {
              url: item.pictureProfile || placeholderImage,
              description: `${item.firstName} ${item.surname}`,
              isContainImage: !res.pictureProfile,
            },
            title: `${item.firstName} ${item.surname}`,
            subline: getCountryName(locale, item.country) || '',
            link: { linkToPage: item.slug },
          };
        });
        setJurors(data);
        setTotal(res.count);
      });
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  if (layout === 'grid') {
    return (
      <div className={combine(beigeBackground && styles.beigeBg)}>
        <div className={styles.gridContainer}>
          {jurors.length > 0
            ? jurors.map((item, index) => (
                <TeaserItem key={index} {...item} aspectRatio="1/1" />
              ))
            : Array.from(Array(amountOfRandomTeaser).keys()).map((_, index) => (
                <TeaserItem key={index} isSkeleton aspectRatio="1/1" />
              ))}
        </div>
        <div className={styles.button}>
          <Link
            link={{
              linkToPage: getJurorFilterPageLink(),
            }}
            asButton
            btnType="primary"
          >
            {getCopy('btn.seeAllJurors', microcopy)}
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className={combine(beigeBackground && styles.beigeBg)}>
      <CustomScrollable className={styles.scrollContainer}>
        {firstItemHeadline && (
          <FirstItem
            headline={firstItemHeadline}
            text={firstItemText}
            link={firstItemLink}
          />
        )}
        <div className={styles.innerContainer}>
          {jurors.length > 0
            ? jurors.map((item, index) => (
                <TeaserItem
                  key={index}
                  {...item}
                  isInSlider
                  aspectRatio="1/1"
                  isSmall
                />
              ))
            : Array.from(Array(3).keys()).map((_, index) => (
                <TeaserItem
                  key={index}
                  isSkeleton
                  isInSlider
                  aspectRatio="1/1"
                  isSmall
                />
              ))}
        </div>
      </CustomScrollable>
      <CustomScrollable className={styles.scrollContainerSm}>
        <div className={styles.innerContainerSm}>
          {jurors.length > 0
            ? jurors.map((item, index) => (
                <TeaserItem
                  key={index}
                  {...item}
                  isInSlider
                  aspectRatio="1/1"
                  isSmall
                />
              ))
            : Array.from(Array(3).keys()).map((_, index) => (
                <TeaserItem
                  key={index}
                  isSkeleton
                  isInSlider
                  aspectRatio="1/1"
                  isSmall
                />
              ))}
        </div>
      </CustomScrollable>
    </div>
  );
};

RandomJurors.propTypes = {
  amountOfRandomTeaser: pt.number.isRequired,
  firstItemHeadline: pt.shape(headlinePropTypes),
  firstItemText: pt.string,
  firstItemLink: pt.shape(linkProptypes),
  setTotal: pt.func,
};

RandomJurors.defaultProps = {
  firstItemHeadline: null,
  firstItemText: '',
  firstItemLink: null,
  setTotal: () => {},
};

export default RandomJurors;
