import React, { useContext } from 'react';
import pt from 'prop-types';
import { getCopy } from '../../../helpers/copy';
import Text from '../text';
import { MicrocopyContext } from '../../../context/microcopy.context';
import * as styles from './styles.module.scss';
import CompanyDetailSocials from './components/companyDetailIcons';
import ArticleDetailSocials from './components/articleDetailIcons';
import { combine } from '../../../helpers/styles';
import DefaultSocials from './components/defaultIcons';

const SocialShare = ({
  shareURL,
  displayLabel,
  className,
  isDefault,
  isCompanyDetail,
  isArticleDetail,
  isContributionDetail,
  hasContact,
  socialLinks,
  companyWebsite
}) => {
  const microcopy = useContext(MicrocopyContext);
  return (
    <div className={combine(className, isCompanyDetail && styles.hideSm, isContributionDetail && styles.verticallyAligned)}>
      {displayLabel && (
        <Text className={combine(styles.label, isContributionDetail && styles.contributionShareText)}>
          {getCopy(isContributionDetail ? 'socialShare.contributions.label' : 'socialShare.label', microcopy)}
        </Text>
      )}
      <div className={combine(styles.iconWrapper, isContributionDetail && styles.noMargins)}>
        {isCompanyDetail && (
          <CompanyDetailSocials
            socialLinks={socialLinks}
            hasContact={hasContact}
            companyWebsite={companyWebsite}
          />
        )}
        {isArticleDetail && <ArticleDetailSocials shareURL={shareURL} />}
        {isDefault && !isCompanyDetail && !isArticleDetail && (
          <DefaultSocials shareURL={shareURL} />
        )}
      </div>
    </div>
  );
};

SocialShare.propTypes = {
  shareURL: pt.string.isRequired,
  displayLabel: pt.bool,
  className: pt.string,
  isCompanyDetail: pt.bool,
  isArticleDetail: pt.bool,
  isContributionDetail: pt.bool,
  isDefault: pt.bool,
  socialLinks: pt.array,
  companyWebsite: pt.string
};

SocialShare.defaultProps = {
  className: '',
  displayLabel: false,
  isCompanyDetail: false,
  isArticleDetail: false,
  isContributionDetail: false,
  isDefault: true,
  socialLinks: [],
  companyWebsite: ''
};

export default SocialShare;
